import Vue from "vue";
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/components/pages/Home.vue'),
    meta: { title: '首页' },
    redirect: '/Home',
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import('@/components/pages/Home.vue')
  },
  {
    path: '/Product',
    name: 'Product',
    component: () => import('@/components/pages/Product.vue')
  },
  {
    path: '/Case',
    name: 'Case',
    component: () => import('@/components/pages/Case.vue')
  },
  {
    path: '/About',
    name: 'About',
    meta: { title: '关于我们' },
    component: () => import('@/components/pages/About.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router 